import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Wrapper = styled.section`
  padding-bottom: 68px;

  @media (max-width: 375px) {
    padding-bottom: 40px;
  }
`

const StyledSlider = styled(Slider)`
  font-size: 0;

  .slick-dots {
    margin: 28px 0 0 0;
    padding: 0;
    list-style: none;
    display: flex !important;
    align-items: center;
    justify-content: center;

    @media (max-width: 375px) {
      margin-top: 0;
    }

    li {
      padding: 0 12px;
    }

    button {
      background: 0;
      margin: 0;
      padding: 0;
      border: 0;
      width: 44px;
      height: 44px;
      border-bottom: 1px solid ${props => props.theme.colors.primary};
      position: relative;
      outline: none;

      &::after {
        content: '';
        height: 5px;
        width: 100%;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${props => props.theme.colors.primary};
        transition: transform 0.35s;
        transform-origin: center bottom;
        transform: scaleY(0);
      }
    }

    li.slick-active button::after {
      transform: scaleY(1);
    }
  }

  img {
    display: block;
  }
`

const StyledImg = styled(Img)`
  min-height: 190px;
  max-height: 690px;
`

const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: true,
}

const Gallery = () => {
  const data = useStaticQuery(graphql`
    fragment GalleryImage on File {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    query {
      galleryImage1: file(relativePath: { eq: "kanzlei_01.jpg" }) {
        ...GalleryImage
      }
      galleryImage2: file(relativePath: { eq: "kanzlei_02.jpg" }) {
        ...GalleryImage
      }
      galleryImage3: file(relativePath: { eq: "kanzlei_03.jpg" }) {
        ...GalleryImage
      }
    }
  `)

  return (
    <Wrapper>
      <StyledSlider {...settings}>
        <StyledImg fluid={data.galleryImage1.childImageSharp.fluid} />
        <StyledImg fluid={data.galleryImage2.childImageSharp.fluid} />
        <StyledImg fluid={data.galleryImage3.childImageSharp.fluid} />
      </StyledSlider>
    </Wrapper>
  )
}

export default Gallery
