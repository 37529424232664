import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Content from './Content'
import { styles } from './Button'
import { useIntl } from '../../plugins/gatsby-plugin-intl'

const Wrapper = styled.section`
  background-color: ${props => props.theme.colors.primary};
  color: #fff;
`

const Main = styled.div`
  font-size: 1.5rem;
  line-height: 1.5;
  text-align: center;
  padding-top: 190px;
  padding-bottom: 200px;
  width: 890px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    padding-top: 100px;
    padding-bottom: 120px;
    font-size: 1.25rem;
  }

  @media (max-width: 375px) {
    padding-top: 60px;
    padding-bottom: 80px;
  }
`

const Text = styled.div`
  margin-bottom: 110px;

  @media (max-width: 768px) {
    margin-bottom: 80px;
  }

  @media (max-width: 375px) {
    margin-bottom: 60px;
  }
`

const Action = styled.div`
  text-align: center;
`

const StyledButton = styled(Link)`
  ${styles}
  padding-bottom: 13px;

  @media (max-width: 768px) {
    font-size: 1.125rem;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: inline-flex;
    align-items: center;

    svg {
      width: 15px;
    }
  }
`

const Icon = styled.svg`
  display: inline-block;
  height: 18px;
  width: auto;
  margin-right: 14px;
  position: relative;
  top: 2px;

  @media (max-width: 768px) {
    height: 16px;
    margin-right: 8px;
  }
`

const CTA = () => {
  const intl = useIntl()

  return (
    <Wrapper>
      <Content>
        <Main>
          <Fade bottom>
            <Text>{intl.formatMessage({ id: 'cta.intro' })}</Text>
          </Fade>
          <Action bottom>
            <Action>
              <StyledButton
                to={
                  intl.locale === 'de'
                    ? '/de/taetigkeitsbereiche'
                    : '/en/services'
                }
                design="focus"
              >
                <Icon
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 7.05 10.84"
                >
                  <polyline
                    points="0.32 0.38 6.28 5.42 0.32 10.46"
                    fill="none"
                    stroke="currentColor"
                    strokeMiterlimit={10}
                  />
                </Icon>{' '}
                {intl.formatMessage({ id: 'cta.label' })}
              </StyledButton>
            </Action>
          </Action>
        </Main>
      </Content>
    </Wrapper>
  )
}

export default CTA
